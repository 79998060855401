<template>
    <div>
        <button @click="downloadPdf">Download PDF</button>

          <div id="app">
          <div class="container">

            <div class='row' style='margin-bottom:20px !important;padding-bottom:-120px !important;'>
                    <div class='col-6 w-50 d-inline'>
                        <p style='color:green;font-size: 18px;'>ECOGEN INDUSTRIES</p>
                        <p style='color:#943a2c;font-size:14px;'>Manufacturer of CALIBRE & DVONN PLYWOOD</p>
                        <p>Sy.No 39/1, Pattanagere Road,
                            Near R.V Engineering College, R.V.Post, Mysore Road, Bangalore - 560059.
                        </p>

                    </div>
                    <div class='col-6 w-50 d-inline' style='text-align:right;margin-top: 15px;'>
                        <p>GSTIN : 29AAGFE0730R1Z2</p>
                        <p>Email: Hello@calibreply.com</p>
                        <p>Web: www.calibreply.com</p>

                    </div>
                </div>
                <h3 style='text-align:center;'>SALES DETAILS</h3>

                <table id='customers'  width='100%'>
                <tr>

                     <th>Billing Date</th>
                     <th>Client Name</th>
                     <th>Billing Address</th>
                     <th>Delivery Address</th>
                     <th>Total Amount</th>
                </tr>

                <tr>
                     <td>{{salesDetails.billing_date}}</td>
                    <td>{{salesDetails.client_name}}</td>
                    <td>{{salesDetails.billing_address}}</td>
                    <td>{{salesDetails.delivery_address}}</td>
                    <td>{{salesDetails.total_amount}}</td>

                </tr>

            </table>
            <br />
            <h3>PRODUCT DETAILS</h3>
                      <br />

                      <table id='customers'  width='100%'>

            <tr>

                 <th>Product Name</th>
                 <th>Size</th>
                 <th>Thickness</th>
                 <th>Per Sqft. Price</th>
                 <th>Quantity</th>
                 <th>Total</th>

            </tr>
            <template v-for="pdata in product_details">
                     <tr :key="pdata.id">
                                <td>{{pdata.pname}}</td>
                                <td>{{pdata.size}}</td>
                                <td>{{pdata.thickness}}</td>
                                <td>{{pdata.price}}</td>
                                <td>{{pdata.quantity}}</td>
                                <td>{{pdata.total}}</td>

                            </tr>
            </template>

                          </table>
                      <br />

                      <table id='customers'  width='100%'>

                            <tr>

                                 <th>Other Charge</th>
                                 <th>Other Charge Amount</th>
                                 <th>Grand Total</th>
                                 <th>Paid Amount</th>
                                 <th>Due Amount</th>
                            </tr>

                            <tr>

                                <td>{{salesDetails.other_charge}}</td>
                                <td>{{salesDetails.other_charge_amount}}</td>
                                <td>{{salesDetails.grand_total}}</td>
                                <td>{{salesDetails.paid_amount}}</td>
                                <td>{{salesDetails.due_amount}}</td>

                            </tr>
                      </table>
                       <br />

                       <h3>REMARKS</h3>

                      <div style='border: 1px solid #000; padding:10px;'>{{salesDetails.remark}}</div>

          </div>
        </div>

    </div>

  </template>

<script>
import axios from 'axios'
import html2pdf from 'html2pdf.js'
export default {
  name: 'App',
  data () {
    return {
      salesDetails: [],
      clientData: [],
      product_details: []
    }
  },
  mounted () {
    this.getSalesData()
  },
  methods: {
    getSalesData () {
      var ev = this
      axios({
        method: 'POST',
        url: this.$root.URL_ROOT + 'inventory_api.php',
        data: {
          type: 'getDraftSaleById',
          sid: this.$route.params.id
        }
      }).then(function (response) {
        var data = response.data
        console.log(data, 'sales data')
        if (data.status === 'success') {
          ev.salesDetails = data.salesdata
          ev.clientData = data.client
          ev.product_details = data.product_details
        }
      })
    },
    downloadPdf () {
      const element = document.getElementById('app')
      html2pdf()
        .from(element)
        .save('my-pdf-document.pdf')
    }
  }
}
</script>

  <style>
  body{
    background-color: #fff !important;
  }
  #app {
    font-family: Arial, sans-serif;
    text-align: center;
    padding-top: 50px;
    background-color: #fff;
  }
  #customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.d-inline{
    display:inline-block !important;
}
.w-50{
    width:50%;
}
.container{
  padding-bottom: 10%;
}
.top-container{
  padding-left: 5%;
  padding-right: 5%;
}
  </style>
