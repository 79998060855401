<template>
    <footer class="sticky-footer bg-white">
        <div class="container my-auto">
            <div class="copyright text-center my-auto">
                <span>Copyright &copy; Calibreply 2023</span>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
    components: {},
    // props: ["isHas"],
    data() {
        return {
            
        };
    },
    mounted() {
        
         
    },
    watch: {
        
    },
    methods: {
        
    },
};
</script>